// src/App.js

import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  onAuthStateChanged,
  signOut,
  getRedirectResult,
} from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

import { auth, db } from './firebase'; // Ensure auth and db are correctly exported
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AccountPage from './components/accounts/AccountPage';
import SuperAdminPanel from './components/SuperAdminPanel';
import PrivateRoute from './routes/PrivateRoute';
import Navbar from './components/common/Navbar';
import ContextPage from './components/contexts/ContextPage';
import TagPage from './components/tags/TagPage'; // Ensure TagPage is correctly imported

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme'; // Import the custom theme

// Import additional necessary components from MUI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button'; // Import Button for sign-out

const App = () => {
  const [user, setUser] = useState(undefined); // undefined: loading; null: not logged in; user object: logged in
  const [role, setRole] = useState(undefined);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out.');
        navigate('/login'); // Redirect to login after sign-out
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  // Function to fetch or create user role
  const getUserRole = async (currentUser) => {
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        console.log('User profile exists in Firestore');
        return userDoc.data().role;
      } else {
        // User document doesn't exist; create it
        await setDoc(userDocRef, {
          uid: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          role: 'user', // Set default role
          authorizedUsers: [currentUser.uid], // Initialize authorizedUsers
          createdAt: serverTimestamp(),
        });
        console.log('User profile created in Firestore from App.js');
        return 'user';
      }
    } catch (error) {
      console.error('Error getting user role:', error);
      return null;
    }
  };

  // Handle the redirect result after authentication
  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          console.log('Redirect login successful:', user);
          setUser(user);

          // Fetch or create user profile in Firestore
          const userRole = await getUserRole(user);
          console.log('User role fetched:', userRole);
          setRole(userRole);

          // Navigate to dashboard
          console.log('Navigating to dashboard...');
          navigate('/');
        } else {
          console.log('No redirect result');
        }
      } catch (error) {
        console.error('Error handling redirect result:', error);
      }
    };

    handleRedirectResult();
  }, [navigate]);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        console.log('User signed in:', currentUser);
        setUser(currentUser);

        // Fetch or create user profile in Firestore
        const userRole = await getUserRole(currentUser);
        console.log('User role fetched:', userRole);
        setRole(userRole);

        // Optionally, navigate to dashboard if not already there
        if (window.location.pathname === '/login') {
          console.log('Redirecting to dashboard...');
          navigate('/');
        }
      } else {
        console.log('No user signed in');
        setUser(null);
        setRole(null);

        // Redirect to login if not already there
        if (window.location.pathname !== '/login') {
          console.log('Redirecting to login...');
          navigate('/login');
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Show a loading state while checking authentication
  if (user === undefined || role === undefined) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Typography variant="h6">Loading...</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  // Handle case where role is null (due to permission issues or missing data)
  if (user && role === null) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
          }}
        >
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            Unable to determine your role. Please contact support or try signing out and signing in again.
          </Alert>
          <Button variant="contained" color="primary" onClick={handleLogout}>
            Sign Out
          </Button>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normalize styles across browsers */}
      <Navbar user={user} onLogout={handleLogout} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/superadmin"
          element={
            <PrivateRoute user={user} role={role} requiredRole="superadmin">
              <SuperAdminPanel />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts/:accountId/contexts/:contextId/tags/:tagId"
          element={
            <PrivateRoute user={user}>
              <TagPage user={user} />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts/:accountId/contexts/:contextId"
          element={
            <PrivateRoute user={user}>
              <ContextPage user={user} />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts/:accountId/*"
          element={
            <PrivateRoute user={user}>
              <AccountPage user={user} />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute user={user}>
              <Dashboard user={user} role={role} />
            </PrivateRoute>
          }
        />
        {/* Add a catch-all route for 404 Not Found */}
        <Route
          path="*"
          element={
            <Container>
              <Typography
                variant="h4"
                color="error"
                align="center"
                sx={{ marginTop: 4 }}
              >
                404 - Page Not Found
              </Typography>
            </Container>
          }
        />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
