// src/components/SuperAdminPanel.js

import React, { useEffect, useState } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const SuperAdminPanel = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch all users
    const usersRef = collection(db, 'users');
    const unsubscribe = onSnapshot(usersRef, (querySnapshot) => {
      const usersData = [];
      querySnapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() });
      });
      setUsers(usersData);
    });

    return () => unsubscribe();
  }, [db]);

  const handleImpersonate = async (userId) => {
    // Call Cloud Function to impersonate user
    try {
      const { getFunctions, httpsCallable } = await import('firebase/functions');
      const functions = getFunctions(firebaseApp);
      const impersonateUser = httpsCallable(functions, 'impersonateUser');
      const result = await impersonateUser({ userId });
      const { token } = result.data;

      // Use the token to re-authenticate
      const { getAuth, signInWithCustomToken } = await import('firebase/auth');
      const auth = getAuth(firebaseApp);
      await signInWithCustomToken(auth, token);
    } catch (error) {
      console.error('Impersonation error:', error);
    }
  };

  return (
    <div>
      <h1>SuperAdmin Panel</h1>
      <h2>Users</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.displayName} ({user.email})
            <button onClick={() => handleImpersonate(user.id)}>Impersonate</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SuperAdminPanel;
