// src/components/accounts/CreateAccountModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

const CreateAccountModal = ({ open, onClose, user }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null); // State to handle errors
  const [success, setSuccess] = useState(null); // Optional: State for success messages
  const [loading, setLoading] = useState(false); // State to handle loading

  // Optional: Real-time uniqueness validation
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [checkingName, setCheckingName] = useState(false);

  // Real-Time Validation with Debouncing
  useEffect(() => {
    if (!name.trim()) {
      setIsNameUnique(true);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      try {
        setCheckingName(true);
        const q = query(collection(db, 'accounts'), where('name', '==', name.trim()));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setIsNameUnique(false);
        } else {
          setIsNameUnique(true);
        }
      } catch (error) {
        console.error('Error checking account name uniqueness:', error);
        // Optionally, handle the error differently
        setIsNameUnique(true);
      } finally {
        setCheckingName(false);
      }
    }, 500); // 500ms debounce

    return () => clearTimeout(delayDebounceFn);
  }, [name]);

  const handleCreate = async () => {
    // Basic validations
    if (!name.trim()) {
      setError('Account name is required.');
      return;
    }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors
      setSuccess(null); // Reset success message

      // Reference to the 'accounts' collection
      const accountsRef = collection(db, 'accounts');

      // Create a query against the collection to check for duplicate names
      const q = query(accountsRef, where('name', '==', name.trim()));

      // Execute the query
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If an account with the same name exists
        setError('An account with this name already exists. Please choose a different name.');
        setLoading(false);
        return;
      }

      // Proceed to create the account since no duplicate was found
      await addDoc(accountsRef, {
        name: name.trim(),
        description: description.trim(),
        owner: user.uid,
        createdBy: {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || 'N/A',
        },
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        authorizedUsers: [user.uid], // Owner is automatically authorized
      });

      // Optional: Set success message
      setSuccess('Account created successfully!');

      // Reset fields and optionally close modal after a delay
      setTimeout(() => {
        setName('');
        setDescription('');
        setError(null);
        setSuccess(null);
        onClose();
      }, 1500); // 1.5 seconds delay
    } catch (err) {
      console.error('Error creating account:', err);
      setError('Failed to create account. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
    setName('');
    setDescription('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        {/* Display Success Message */}
        {success && (
          <Alert
            severity="success"
            onClose={() => setSuccess(null)}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}
        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Account Name"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setError(null);
          }}
          helperText={
            checkingName
              ? 'Checking name uniqueness...'
              : !isNameUnique
              ? 'This account name is already taken. Please choose another.'
              : 'Enter the name of the account'
          }
          error={!isNameUnique}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          helperText="Enter a description for the account (optional)"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={
            loading ||
            !name.trim() ||
            !isNameUnique ||
            checkingName
          }
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateAccountModal.propTypes = {
  open: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired, // User ID
    email: PropTypes.string.isRequired, // User email
    displayName: PropTypes.string, // User display name (optional)
  }).isRequired,
};

export default CreateAccountModal;
