// src/components/common/Navbar.js

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const Navbar = ({ user, onLogout }) => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer
  
    const handleLogout = () => {
      onLogout();
      navigate('/login');
    };
  
    const toggleDrawer = (open) => (event) => {
      // Close drawer on certain key presses
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };
  
    // Navigation items
    const navItems = [
      {
        text: 'Accounts',
        icon: <AccountBalanceIcon />,
        path: '/',
      },
      // Future navigation items can be added here
    ];
  
    // Drawer content
    const drawerContent = (
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        style={{ width: 250 }}
      >
        <List>
          {navItems.map((item) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* Additional navigation items or sections can be added here */}
      </div>
    );
  
    return (
      <>
        <AppBar position="static">
          <Toolbar>
            {user && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="tag" sx={{ flexGrow: 1 }}>
              <Link to="/" style={{ color: 'inherit', fontfamily: 'inherit', textDecoration: 'none' }}>
                tag
                <Typography variant="it" sx={{ flexGrow: 1 }}>It</Typography>
              </Link>
            </Typography>
            {user && (
              <Button variant="contained"
              color="secondary"
              size="small"
              onClick={handleLogout}>
                Logout
              </Button>
            )}
            {!user && (
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {/* Drawer component */}
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
      </>
    );
  };
  
  export default Navbar;
  