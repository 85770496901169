// src/components/contexts/CreateContextModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Alert,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

// Define Type options
const TYPE_OPTIONS = [
  { value: 'website', label: 'Website' },
  { value: 'app', label: 'App' },
  { value: 'campaign', label: 'Campaign' },
  { value: 'server', label: 'Server' },
  { value: 'other', label: 'Other' },
];

// Define Platform options
const PLATFORM_OPTIONS = [
  { value: 'browser', label: 'Browser' },
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
  { value: 'roku', label: 'Roku' },
  { value: 'other', label: 'Other' },
];

const CreateContextModal = ({ open, onClose, user, accountId }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('active');
  const [platform, setPlatform] = useState('');
  const [url, setUrl] = useState('');
  const [app, setApp] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // For success messages
  const [loading, setLoading] = useState(false); // For loading state

  // Optional: Real-time uniqueness validation
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [checkingName, setCheckingName] = useState(false);

  // Clear conditional fields when 'type' changes
  useEffect(() => {
    if (type !== 'app') {
      setPlatform('');
      setApp('');
    }
    if (type !== 'website') {
      setUrl('');
    }
  }, [type]);

  // Optional: Real-time uniqueness check with debouncing
  useEffect(() => {
    if (!name.trim()) {
      setIsNameUnique(true);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      try {
        setCheckingName(true);
        const contextsRef = collection(db, 'accounts', accountId, 'contexts');
        const q = query(contextsRef, where('name', '==', name.trim()));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setIsNameUnique(false);
        } else {
          setIsNameUnique(true);
        }
      } catch (error) {
        console.error('Error checking context name uniqueness:', error);
        // Optionally, handle the error differently
        setIsNameUnique(true);
      } finally {
        setCheckingName(false);
      }
    }, 500); // 500ms debounce

    return () => clearTimeout(delayDebounceFn);
  }, [name, accountId]);

  const handleCreate = async () => {
    // Basic validations
    if (!name.trim()) {
      setError('Name is required.');
      return;
    }
    if (!type) {
      setError('Type is required.');
      return;
    }

    // Additional validations based on 'type'
    if (type === 'app') {
      if (!platform) {
        setError('Platform is required for App type.');
        return;
      }
      if (!app.trim()) {
        setError('App ID is required for App type.');
        return;
      }
    }
    if (type === 'website') {
      if (!url.trim()) {
        setError('URL is required for Website type.');
        return;
      }
      // Optional: Validate URL format
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // domain name
          'localhost|' + // localhost
          '\\d{1,3}(\\.\\d{1,3}){3})' + // OR IP (v4) address
          '(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*' + // port and path
          '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
          '(\\#[-a-zA-Z0-9_]*)?$',
        'i'
      ); // fragment locator
      if (!urlPattern.test(url)) {
        setError('Please enter a valid URL.');
        return;
      }
    }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors

      // Reference to the 'contexts' subcollection under the specific account
      const contextsRef = collection(db, 'accounts', accountId, 'contexts');

      // Create a query against the collection to check for duplicate names
      const q = query(contextsRef, where('name', '==', name.trim()));

      // Execute the query
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If a context with the same name exists
        setError('A context with this name already exists. Please choose a different name.');
        setLoading(false);
        return;
      }

      // Construct the context data with accountId
      const contextData = {
        name: name.trim(),
        type,
        description: description.trim() || null,
        status,
        platform: type === 'app' ? platform : null,
        url: type === 'website' ? url.trim() : null,
        app: type === 'app' ? app.trim() : null,
        createdBy: {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || 'N/A',
        },
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
        authorizedUsers: [user.uid], // Initialize authorizedUsers with the creator
        accountId, // Associate context with its parent account
      };

      await addDoc(contextsRef, contextData);

      setSuccess('Context created successfully!'); // Set success message

      // Optionally, auto-close the modal after a delay
      setTimeout(() => {
        // Reset fields
        setName('');
        setType('');
        setDescription('');
        setStatus('active');
        setPlatform('');
        setUrl('');
        setApp('');
        setSuccess(null);
        onClose();
      }, 1500); // Adjust the delay as needed (e.g., 1.5 seconds)
    } catch (error) {
      console.error('Error creating context:', error);
      setError('Failed to create context. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
    setName('');
    setType('');
    setDescription('');
    setStatus('active');
    setPlatform('');
    setUrl('');
    setApp('');
    setIsNameUnique(true);
    setCheckingName(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Context</DialogTitle>
      <DialogContent>
        {/* Display Success Message */}
        {success && (
          <Alert
            severity="success"
            onClose={() => setSuccess(null)}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}
        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Name Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              helperText={
                checkingName
                  ? 'Checking name uniqueness...'
                  : !isNameUnique
                  ? 'This name is already taken. Please choose another.'
                  : 'Enter the name of the context'
              }
              error={!isNameUnique}
            />
          </Grid>

          {/* Type Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              fullWidth
              helperText="Select the context type"
            >
              {TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Platform Field - Only appears if Type = App */}
          {type === 'app' && (
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Platform"
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                required={type === 'app'}
                fullWidth
                helperText="Select the platform"
              >
                {PLATFORM_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {/* App ID Field - Only appears if Type = App */}
          {type === 'app' && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="App ID"
                value={app}
                onChange={(e) => setApp(e.target.value)}
                required={type === 'app'}
                fullWidth
                helperText="Enter the unique App ID"
              />
            </Grid>
          )}

          {/* URL Field - Only appears if Type = Website */}
          {type === 'website' && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required={type === 'website'}
                fullWidth
                helperText="Enter the URL for your website"
              />
            </Grid>
          )}

          {/* Description Field */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              helperText="Enter a description for the context (optional)"
            />
          </Grid>

          {/* Status Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
              fullWidth
              helperText="Set the status of the context"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={
            loading ||
            !name.trim() ||
            !type ||
            (type === 'app' && (!platform || !app.trim())) ||
            (type === 'website' && !url.trim()) ||
            !isNameUnique
          }
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for type checking and better maintainability
CreateContextModal.propTypes = {
  open: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired, // User ID
    email: PropTypes.string.isRequired, // User email
    displayName: PropTypes.string, // User display name (optional)
  }).isRequired,
  accountId: PropTypes.string.isRequired, // ID of the parent account
};

export default CreateContextModal;
