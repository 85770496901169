// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateAccountModal from './accounts/CreateAccountModal';
import DataTable from './common/DataTable';
import EditAccountModal from './accounts/EditAccountModal';

const Dashboard = ({ user }) => {
  const [accounts, setAccounts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    if (!user) {
      setAccounts([]);
      return;
    }

    // Define the query to fetch accounts where 'authorizedUsers' array contains the user's UID
    const accountsRef = collection(db, 'accounts');
    const q = query(
      accountsRef,
      where('authorizedUsers', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const accountsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAccounts(accountsData);
        setError(null);
      },
      (error) => {
        console.error('Error fetching accounts:', error);
        setError('Failed to fetch accounts. Please try again.');
      }
    );

    return () => unsubscribe();
  }, [user]);

  const handleCreateAccount = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEdit = (account) => {
    setSelectedAccount(account);
    setOpenEditModal(true);
  };
  
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedAccount(null);
  };

  const handleDelete = async (accountId) => {
    // Implement delete functionality
    console.log('Delete account:', accountId);
    // Example:
    // try {
    //   await deleteDoc(doc(db, 'accounts', accountId));
    // } catch (error) {
    //   console.error('Error deleting account:', error);
    // }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.25,
      minwidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Link to={`/accounts/${params.row.id}`} style={{ textDecoration: 'none' }}>
          {params.value}
        </Link>
      ),
    },
    { field: 'id', 
      headerName: 'ID',
      flex: 0.25,
      minwidth: 150, 
      sortable: true 
    },
    { field: 'description', 
      headerName: 'Description', 
      flex: 0.25,
      minwidth: 150, 
      sortable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.25,
      minwidth: 200,
      sortable: false,
      renderCell: (params) => (
        <Box display="inline-flex" gap={1}>
              <Button
                variant="contained"
                //color="primary"
                size="small"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Container>
      {/* Row 1: Page Title (left aligned) */}
      <Grid container spacing={2} alignItems="center" sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4">
            Accounts
          </Typography>
        </Grid>
        <Grid item sm={8}>
          {/* Empty or additional content can go here */}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleCreateAccount}
        sx={{ marginBottom: 2 }}
      >
        Create Account
      </Button>
      </Grid>
      {/* Display error if any */}
      {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )}
      {/* Place the DataTable component here */}
      <DataTable rows={accounts} columns={columns} />
      <CreateAccountModal open={openModal} onClose={handleCloseModal} user={user} />
      {/* Edit Account Modal */}
      {selectedAccount && (
        <EditAccountModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          account={selectedAccount}
        />
      )}
    </Container>
  );
};

export default Dashboard;
