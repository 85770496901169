// src/components/accounts/EditAccountModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  Grid,
  CircularProgress,
} from '@mui/material';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

const EditAccountModal = ({ open, onClose, account, accountId }) => {
  const [name, setName] = useState(account.name || '');
  const [description, setDescription] = useState(account.description || '');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // State for success messages
  const [loading, setLoading] = useState(false); // State to handle loading

  useEffect(() => {
    setName(account.name || '');
    setDescription(account.description || '');
    setError(null);
    setSuccess(null);
  }, [account]);

  const handleUpdate = async () => {
    // Since 'name' is read-only, we don't need to validate or update it
    // Validate 'description' if it's required
    // Assuming 'description' is optional based on the initial code
    // If 'description' should be required, uncomment the following lines:

    // if (!description.trim()) {
    //   setError('Description is required.');
    //   return;
    // }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors
      setSuccess(null); // Reset success message

      const accountDocRef = doc(db, 'accounts', accountId);
      const updatedData = {
        // 'name' is read-only and should not be updated
        description: description.trim() || null,
        lastUpdated: serverTimestamp(),
      };

      await updateDoc(accountDocRef, updatedData);

      setSuccess('Account updated successfully!'); // Set success message

      // Optionally, auto-close the modal after a delay
      setTimeout(() => {
        setDescription('');
        setError(null);
        setSuccess(null);
        onClose();
      }, 1500); // 1.5 seconds delay
    } catch (err) {
      console.error('Error updating account:', err);
      setError('Failed to update account. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
    setName(account.name || '');
    setDescription(account.description || '');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Account</DialogTitle>
      <DialogContent>
        {/* Display Success Message */}
        {success && (
          <Alert
            severity="success"
            onClose={() => setSuccess(null)}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}
        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Account Name Field - Read-Only */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Account Name"
              value={name}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              helperText="Account name (read-only)"
            />
          </Grid>

          {/* Additional Fields Based on Account Type (If Applicable) */}
          {/* 
          Example:
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Account Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              fullWidth
              helperText="Select the account type"
            >
              {ACCOUNT_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          */}

          {/* Description Field */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              helperText="Enter a description for the account (optional)"
            />
          </Grid>

          {/* Add more fields as necessary */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAccountModal.propTypes = {
  open: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  account: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    // Add more fields as necessary
  }).isRequired,
  accountId: PropTypes.string.isRequired, // ID of the account to edit
};

export default EditAccountModal;
