// src/components/accounts/AccountPage.js

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import EntityPage from '../EntityPage';
import CreateContextModal from '../contexts/CreateContextModal';
import EditContextModal from '../contexts/EditContextModal';
import EditAccountModal from './EditAccountModal';
import DisplayFields from '../common/DisplayFields';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import PropTypes from 'prop-types';

const AccountPage = () => {
  const { accountId } = useParams();
  const user = useContext(UserContext); // Consume user from context

  console.log('AccountPage received user from context:', user);
  console.log('AccountPage received accountId:', accountId);

  const displayFields = [
    {
      field: 'name',
      label: 'Name',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'description',
      label: 'Description',
      valueGetter: (value) => value || 'No description provided.',
    },
    {
      field: 'owner',
      label: 'Owner',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'createdAt',
      label: 'Created At',
      valueGetter: (value) =>
        value ? new Date(value.toDate()).toLocaleString() : 'N/A',
    },
    {
      field: 'lastUpdated',
      label: 'Last Updated',
      valueGetter: (value) =>
        value ? new Date(value.toDate()).toLocaleString() : 'N/A',
    },
  ];

  const tableColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Link

          to={`/accounts/${accountId}/contexts/${params.row.id}`}
          style={{ textDecoration: 'none', color: '#1976d2' }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (params.value === 'active' ? 'Active' : 'Inactive'),
    },
    // Add more columns as needed
  ];

  return (
    <EntityPage
      entityName="Account"
      childEntityName="Context"
      displayFields={displayFields}
      tableColumns={tableColumns}
      CreateModalComponent={CreateContextModal}
      EditModalComponent={EditContextModal}
      EditMainModalComponent={EditAccountModal}
      accountId={accountId} // Pass accountId to EntityPage
      user={user} // **Pass the user prop here**
    />
  );
};

AccountPage.propTypes = {
  // No props expected since using context and useParams
};

export default AccountPage;
