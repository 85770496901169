// src/components/DisplayFields.js

import React from 'react';
import { Grid, Typography, Paper, Divider } from '@mui/material';

const DisplayFields = ({ fields }) => {
  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Grid container spacing={2}>
        {fields && fields.length > 0 ? (
          fields.map((field) => (
            <React.Fragment key={field.field}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="textSecondary">
                  {field.label}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {field.value !== undefined && field.value !== null ? field.value : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body1">No details available.</Typography>
        )}
      </Grid>
    </Paper>
  );
};

export default DisplayFields;
