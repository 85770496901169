// src/components/tags/CreateTagModal.js 

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Alert,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { collection, addDoc, updateDoc, doc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

// Define Type options (if needed in the future)
const TAG_TYPE_OPTIONS = [
  { value: 'Event Tracking-SDK', label: 'Event Tracking-SDK' },
  { value: 'Event Tracking-Image', label: 'Event Tracking-Image' },
  { value: 'Click Tracking', label: 'Click Tracking' },
];

// Define Platform options
const PLATFORM_OPTIONS = [
  { value: 'browser', label: 'Browser' },
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
  { value: 'roku', label: 'Roku' },
  { value: 'other', label: 'Other' },
];

const CreateTagModal = ({ open, onClose, user, accountId, contextId }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('Track');
  const [setCookie, setSetCookie] = useState(true);
  const [cookieDomain, setCookieDomain] = useState('');
  const [cookieName, setCookieName] = useState(''); // New State
  const [sameSiteMode, setSameSiteMode] = useState('None');
  const [collectDeviceData, setCollectDeviceData] = useState(true);
  const [piiHashFlags, setPiiHashFlags] = useState({
    ipAddress: false,
    email: false,
    firstName: false,
    lastName: false,
    fullName: false,
  });
  const [allowedRedirectDomains, setAllowedRedirectDomains] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // Optional: State for success messages
  const [loading, setLoading] = useState(false); // State to handle loading

  // Optional: Real-time uniqueness validation
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [checkingName, setCheckingName] = useState(false);

  // Real-Time Validation with Debouncing
  useEffect(() => {
    if (!name.trim()) {
      setIsNameUnique(true);
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      try {
        setCheckingName(true);
        const tagsRef = collection(db, 'accounts', accountId, 'contexts', contextId, 'tags');
        const q = query(tagsRef, where('name', '==', name.trim()));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setIsNameUnique(false);
        } else {
          setIsNameUnique(true);
        }
      } catch (error) {
        console.error('Error checking tag name uniqueness:', error);
        // Optionally, handle the error differently
        setIsNameUnique(true);
      } finally {
        setCheckingName(false);
      }
    }, 500); // 500ms debounce

    return () => clearTimeout(delayDebounceFn);
  }, [name, accountId, contextId]);

  const handlePiiHashFlagChange = (e) => {
    const { name, checked } = e.target;
    setPiiHashFlags((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleCreate = async () => {
    // Input validations
    if (!name.trim()) {
      setError('Name is required.');
      return;
    }
    if (!type) {
      setError('Type is required.');
      return;
    }
    if (setCookie && !cookieDomain.trim()) {
      setError('Cookie Domain is required when Set Cookie is enabled.');
      return;
    }
    if (setCookie && !sameSiteMode) {
      setError('Same Site Mode is required when Set Cookie is enabled.');
      return;
    }
    if (setCookie && !cookieName.trim()) {
      setError('Cookie Name is required when Set Cookie is enabled.');
      return;
    }
    if (type === 'Click Tracking' && !allowedRedirectDomains.trim()) {
      setError('At least one Redirect Domain is required for Click Tracking type.');
      return;
    }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors
      setSuccess(null); // Reset success message

      // Reference to the 'tags' subcollection under the specific account and context
      const tagsRef = collection(db, 'accounts', accountId, 'contexts', contextId, 'tags');

      // Create a query against the collection to check for duplicate names
      const q = query(tagsRef, where('name', '==', name.trim()));

      // Execute the query
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If a tag with the same name exists
        setError('A tag with this name already exists. Please choose a different name.');
        setLoading(false);
        return;
      }

      // Construct the tag data with accountId and contextId
      const tagData = {
        name: name.trim(),
        type,
        description: description.trim() || null,
        status,
        setCookie,
        collectDeviceData,
        piiHashFlags,
        lastUpdated: serverTimestamp(),
        accountId,   // Added accountId
        contextId,   // Added contextId
      };

      // Conditionally add fields based on user input
      if (setCookie) {
        tagData.cookieDomain = cookieDomain.trim();
        tagData.cookieName = cookieName.trim(); // Added cookieName
        tagData.sameSiteMode = sameSiteMode;
      } else {
        tagData.cookieDomain = null;
        tagData.cookieName = null; // Ensure cookieName is null when setCookie is false
        tagData.sameSiteMode = null;
      }

      if (type === 'Click Tracking') {
        // Assuming allowedRedirectDomains is a comma-separated string
        tagData.allowedRedirectDomains = allowedRedirectDomains
          .split(',')
          .map((domain) => domain.trim())
          .filter((domain) => domain);
      } else {
        tagData.allowedRedirectDomains = null;
      }

      tagData.createdBy = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || 'N/A',
      };
      tagData.createdAt = serverTimestamp();
      tagData.authorizedUsers = [user.uid]; // Initialize authorizedUsers with the creator

      const docRef = await addDoc(tagsRef, tagData);

      // Update the document with tagId
      await updateDoc(docRef, { tagId: docRef.id });

      // Optional: Set success message
      setSuccess('Tag created successfully!');

      // Reset fields and optionally close modal after a delay
      setTimeout(() => {
        setName('');
        setType('');
        setDescription('');
        setStatus('Track');
        setSetCookie(true);
        setCookieDomain('');
        setCookieName(''); // Reset cookieName
        setSameSiteMode('None');
        setCollectDeviceData(true);
        setPiiHashFlags({
          ipAddress: false,
          email: false,
          firstName: false,
          lastName: false,
          fullName: false,
        });
        setAllowedRedirectDomains('');
        setError(null);
        setSuccess(null);
        onClose();
      }, 1500); // 1.5 seconds delay
    } catch (error) {
      console.error('Error creating tag:', error);
      setError('Failed to create tag. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
    setName('');
    setType('');
    setDescription('');
    setStatus('Track');
    setSetCookie(true);
    setCookieDomain('');
    setCookieName(''); // Reset cookieName
    setSameSiteMode('None');
    setCollectDeviceData(true);
    setPiiHashFlags({
      ipAddress: false,
      email: false,
      firstName: false,
      lastName: false,
      fullName: false,
    });
    setAllowedRedirectDomains('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Create New Tag</DialogTitle>
      <DialogContent>
        {/* Display Success Message */}
        {success && (
          <Alert
            severity="success"
            onClose={() => setSuccess(null)}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}
        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError(null);
              }}
              required
              fullWidth
              helperText={
                checkingName
                  ? 'Checking name uniqueness...'
                  : !isNameUnique
                  ? 'This tag name is already taken. Please choose another.'
                  : 'Enter a name for your tag'
              }
              error={!isNameUnique}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Type"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setError(null);
              }}
              required
              fullWidth
              helperText="Select the tag type"
            >
              <MenuItem value="Event Tracking-SDK">Event Tracking-SDK</MenuItem>
              <MenuItem value="Event Tracking-Image">Event Tracking-Image</MenuItem>
              <MenuItem value="Click Tracking">Click Tracking</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              helperText="Enter a description for the tag (optional)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setError(null);
              }}
              required
              fullWidth
              helperText="Set the status of the tag"
            >
              <MenuItem value="Track">Track</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setCookie}
                  onChange={(e) => setSetCookie(e.target.checked)}
                  color="primary"
                />
              }
              label="Set Cookie?"
            />
          </Grid>
          {/* Conditional Fields: Cookie Domain, Cookie Name, and Same Site Mode */}
          {setCookie && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cookie Domain"
                  value={cookieDomain}
                  onChange={(e) => setCookieDomain(e.target.value)}
                  required
                  fullWidth
                  helperText="Enter the domain set by the cookie"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cookie Name"
                  value={cookieName}
                  onChange={(e) => setCookieName(e.target.value)}
                  required
                  fullWidth
                  helperText="You will need to set a Cookie Name when you set a cookie"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="The name of the cookie to be set">
                          <InfoIcon color="action" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Same Site Mode"
                  value={sameSiteMode}
                  onChange={(e) => setSameSiteMode(e.target.value)}
                  required
                  fullWidth
                  helperText="Select the same site mode"
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Lax">Lax</MenuItem>
                  <MenuItem value="Strict">Strict</MenuItem>
                </TextField>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={collectDeviceData}
                  onChange={(e) => setCollectDeviceData(e.target.checked)}
                  color="primary"
                />
              }
              label="Collect Device Data?"
            />
          </Grid>
          {/* PII Hash Flags */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              PII Privacy Flags
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.ipAddress}
                  onChange={handlePiiHashFlagChange}
                  name="ipAddress"
                  color="primary"
                />
              }
              label="Hash IP Address"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.email}
                  onChange={handlePiiHashFlagChange}
                  name="email"
                  color="primary"
                />
              }
              label="Hash Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.firstName}
                  onChange={handlePiiHashFlagChange}
                  name="firstName"
                  color="primary"
                />
              }
              label="Hash First Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.lastName}
                  onChange={handlePiiHashFlagChange}
                  name="lastName"
                  color="primary"
                />
              }
              label="Hash Last Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.fullName}
                  onChange={handlePiiHashFlagChange}
                  name="fullName"
                  color="primary"
                />
              }
              label="Hash Full Name"
            />
          </Grid>
          {/* Allowed Redirect Domains Field (Conditional) */}
          {type === 'Click Tracking' && (
            <Grid item xs={12}>
              <TextField
                label="Allowed Redirect Domains"
                value={allowedRedirectDomains}
                onChange={(e) => setAllowedRedirectDomains(e.target.value)}
                required
                fullWidth
                helperText="Enter domains separated by commas (e.g., example.com, another.com)"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={
            loading ||
            !name.trim() ||
            !type ||
            !isNameUnique ||
            checkingName
          }
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for type checking and better maintainability
CreateTagModal.propTypes = {
  open: PropTypes.bool.isRequired,          // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired,       // Function to handle closing the modal
  user: PropTypes.shape({                   // User object containing necessary user info
    uid: PropTypes.string.isRequired,       // User ID
    email: PropTypes.string.isRequired,     // User email
    displayName: PropTypes.string,          // User display name (optional)
  }).isRequired,
  accountId: PropTypes.string.isRequired,    // ID of the account
  contextId: PropTypes.string.isRequired,    // ID of the context
};

export default CreateTagModal;
