// src/components/tags/EditTagModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Alert,
  Grid,
  Typography,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

// Define Type options (if needed in the future)
const TAG_TYPE_OPTIONS = [
  { value: 'Event Tracking-SDK', label: 'Event Tracking-SDK' },
  { value: 'Event Tracking-Image', label: 'Event Tracking-Image' },
  { value: 'Click Tracking', label: 'Click Tracking' },
];

const EditTagModal = ({ open, onClose, tag, accountId, contextId }) => {
  const [name, setName] = useState(tag.name || '');
  const [type, setType] = useState(tag.type || '');
  const [description, setDescription] = useState(tag.description || '');
  const [status, setStatus] = useState(tag.status || 'Track');
  const [setCookie, setSetCookie] = useState(
    tag.setCookie !== undefined ? tag.setCookie : true
  );
  const [cookieDomain, setCookieDomain] = useState(tag.cookieDomain || '');
  const [cookieName, setCookieName] = useState(tag.cookieName || ''); // New State
  const [sameSiteMode, setSameSiteMode] = useState(tag.sameSiteMode || 'None');
  const [collectDeviceData, setCollectDeviceData] = useState(
    tag.collectDeviceData !== undefined ? tag.collectDeviceData : true
  );
  const [piiHashFlags, setPiiHashFlags] = useState(
    tag.piiHashFlags || {
      ipAddress: false,
      email: false,
      firstName: false,
      lastName: false,
      fullName: false,
    }
  );
  const [allowedRedirectDomains, setAllowedRedirectDomains] = useState(
    tag.allowedRedirectDomains ? tag.allowedRedirectDomains.join(', ') : ''
  );
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // Optional: State for success messages
  const [loading, setLoading] = useState(false); // State to handle loading

  useEffect(() => {
    setName(tag.name || '');
    setType(tag.type || '');
    setDescription(tag.description || '');
    setStatus(tag.status || 'Track');
    setSetCookie(tag.setCookie !== undefined ? tag.setCookie : true);
    setCookieDomain(tag.cookieDomain || '');
    setCookieName(tag.cookieName || ''); // Reset cookieName
    setSameSiteMode(tag.sameSiteMode || 'None');
    setCollectDeviceData(
      tag.collectDeviceData !== undefined ? tag.collectDeviceData : true
    );
    setPiiHashFlags(
      tag.piiHashFlags || {
        ipAddress: false,
        email: false,
        firstName: false,
        lastName: false,
        fullName: false,
      }
    );
    setAllowedRedirectDomains(
      tag.allowedRedirectDomains ? tag.allowedRedirectDomains.join(', ') : ''
    );
    setError(null);
    setSuccess(null);
  }, [tag]);

  const handlePiiHashFlagChange = (e) => {
    const { name, checked } = e.target;
    setPiiHashFlags((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleUpdate = async () => {
    // Input validations
    // Since 'name' and 'type' are read-only, we don't validate them here
    if (setCookie && !cookieDomain.trim()) {
      setError('Cookie Domain is required when Set Cookie is enabled.');
      return;
    }
    if (setCookie && !sameSiteMode) {
      setError('Same Site Mode is required when Set Cookie is enabled.');
      return;
    }
    if (setCookie && !cookieName.trim()) {
      setError('Cookie Name is required when Set Cookie is enabled.');
      return;
    }
    if (type === 'Click Tracking' && !allowedRedirectDomains.trim()) {
      setError(
        'At least one Redirect Domain is required for Click Tracking type.'
      );
      return;
    }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors
      setSuccess(null); // Reset success message

      const tagDocRef = doc(
        db,
        'accounts',
        accountId,
        'contexts',
        contextId,
        'tags',
        tag.id
      );

      const updatedTagData = {
        // 'name' and 'type' are read-only and should not be updated
        description: description.trim() || null,
        status,
        setCookie,
        collectDeviceData,
        piiHashFlags,
        lastUpdated: serverTimestamp(),
      };

      // Conditionally add fields based on user input
      if (setCookie) {
        updatedTagData.cookieDomain = cookieDomain.trim();
        updatedTagData.cookieName = cookieName.trim(); // Added cookieName
        updatedTagData.sameSiteMode = sameSiteMode;
      } else {
        updatedTagData.cookieDomain = null;
        updatedTagData.cookieName = null; // Ensure cookieName is null when setCookie is false
        updatedTagData.sameSiteMode = null;
      }

      if (type === 'Click Tracking') {
        // Assuming allowedRedirectDomains is a comma-separated string
        updatedTagData.allowedRedirectDomains = allowedRedirectDomains
          .split(',')
          .map((domain) => domain.trim())
          .filter((domain) => domain);
      } else {
        updatedTagData.allowedRedirectDomains = null;
      }

      await updateDoc(tagDocRef, updatedTagData);

      setSuccess('Tag updated successfully!'); // Set success message

      // Optionally, auto-close the modal after a delay
      setTimeout(() => {
        setError(null);
        setSuccess(null);
        onClose();
      }, 1500); // 1.5 seconds delay
    } catch (error) {
      console.error('Error updating tag:', error);
      setError('Failed to update tag. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
    setName(tag.name || '');
    setType(tag.type || '');
    setDescription(tag.description || '');
    setStatus(tag.status || 'Track');
    setSetCookie(tag.setCookie !== undefined ? tag.setCookie : true);
    setCookieDomain(tag.cookieDomain || '');
    setCookieName(tag.cookieName || ''); // Reset cookieName
    setSameSiteMode(tag.sameSiteMode || 'None');
    setCollectDeviceData(
      tag.collectDeviceData !== undefined ? tag.collectDeviceData : true
    );
    setPiiHashFlags(
      tag.piiHashFlags || {
        ipAddress: false,
        email: false,
        firstName: false,
        lastName: false,
        fullName: false,
      }
    );
    setAllowedRedirectDomains(
      tag.allowedRedirectDomains ? tag.allowedRedirectDomains.join(', ') : ''
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Tag</DialogTitle>
      <DialogContent>
        {/* Display Success Message */}
        {success && (
          <Alert
            severity="success"
            onClose={() => setSuccess(null)}
            sx={{ mb: 2 }}
          >
            {success}
          </Alert>
        )}
        {/* Display Error Message */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Name Field - Read-Only */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={name}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              helperText="Tag name (read-only)"
            />
          </Grid>

          {/* Type Field - Read-Only */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Type"
              value={type}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              helperText="Tag type (read-only)"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              helperText="Enter a description for the tag (optional)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setError(null);
              }}
              required
              fullWidth
              helperText="Set the status of the tag"
            >
              <MenuItem value="Track">Track</MenuItem>
              <MenuItem value="Pause">Pause</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setCookie}
                  onChange={(e) => setSetCookie(e.target.checked)}
                  color="primary"
                />
              }
              label="Set Cookie?"
            />
          </Grid>
          {/* Conditional Fields: Cookie Domain, Cookie Name, and Same Site Mode */}
          {setCookie && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cookie Domain"
                  value={cookieDomain}
                  onChange={(e) => setCookieDomain(e.target.value)}
                  required
                  fullWidth
                  helperText="Enter the domain set by the cookie"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Cookie Name"
                  value={cookieName}
                  onChange={(e) => setCookieName(e.target.value)}
                  required
                  fullWidth
                  helperText="You will need to set a Cookie Name when you set a cookie"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="The name of the cookie to be set">
                          <InfoIcon color="action" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Same Site Mode"
                  value={sameSiteMode}
                  onChange={(e) => setSameSiteMode(e.target.value)}
                  required
                  fullWidth
                  helperText="Select the same site mode"
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Lax">Lax</MenuItem>
                  <MenuItem value="Strict">Strict</MenuItem>
                </TextField>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={collectDeviceData}
                  onChange={(e) => setCollectDeviceData(e.target.checked)}
                  color="primary"
                />
              }
              label="Collect Device Data?"
            />
          </Grid>
          {/* PII Hash Flags */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              PII Privacy Flags
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.ipAddress}
                  onChange={handlePiiHashFlagChange}
                  name="ipAddress"
                  color="primary"
                />
              }
              label="Hash IP Address"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.email}
                  onChange={handlePiiHashFlagChange}
                  name="email"
                  color="primary"
                />
              }
              label="Hash Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.firstName}
                  onChange={handlePiiHashFlagChange}
                  name="firstName"
                  color="primary"
                />
              }
              label="Hash First Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.lastName}
                  onChange={handlePiiHashFlagChange}
                  name="lastName"
                  color="primary"
                />
              }
              label="Hash Last Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={piiHashFlags.fullName}
                  onChange={handlePiiHashFlagChange}
                  name="fullName"
                  color="primary"
                />
              }
              label="Hash Full Name"
            />
          </Grid>
          {/* Allowed Redirect Domains Field (Conditional) */}
          {type === 'Click Tracking' && (
            <Grid item xs={12}>
              <TextField
                label="Allowed Redirect Domains"
                value={allowedRedirectDomains}
                onChange={(e) => setAllowedRedirectDomains(e.target.value)}
                required
                fullWidth
                helperText="Enter domains separated by commas (e.g., example.com, another.com)"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          disabled={
            loading ||
            setCookie && (!cookieDomain.trim() || !sameSiteMode || !cookieName.trim()) ||
            type === 'Click Tracking' && !allowedRedirectDomains.trim()
          }
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for type checking and better maintainability
EditTagModal.propTypes = {
  open: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    setCookie: PropTypes.bool,
    cookieDomain: PropTypes.string,
    cookieName: PropTypes.string, // Added PropTypes for cookieName
    sameSiteMode: PropTypes.string,
    collectDeviceData: PropTypes.bool,
    piiHashFlags: PropTypes.shape({
      ipAddress: PropTypes.bool,
      email: PropTypes.bool,
      firstName: PropTypes.bool,
      lastName: PropTypes.bool,
      fullName: PropTypes.bool,
    }),
    allowedRedirectDomains: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  accountId: PropTypes.string.isRequired,
  contextId: PropTypes.string.isRequired,
};

export default EditTagModal;
