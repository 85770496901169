// src/components/contexts/ContextPage.js

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import EntityPage from '../EntityPage';
import CreateTagModal from '../tags/CreateTagModal';
import EditTagModal from '../tags/EditTagModal';
import EditContextModal from './EditContextModal';
import DisplayFields from '../common/DisplayFields';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';
import PropTypes from 'prop-types'; // Import PropTypes

const ContextPage = () => {
  const { accountId, contextId } = useParams();
  const user = useContext(UserContext); // Consume user from context

  console.log('ContextPage received user from context:', user);
  console.log('ContextPage received accountId:', accountId, 'contextId:', contextId);

  const displayFields = [
    {
      field: 'name',
      label: 'Name',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'description',
      label: 'Description',
      valueGetter: (value) => value || 'No description provided.',
    },
    {
      field: 'type',
      label: 'Type',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'status',
      label: 'Status',
      valueGetter: (value) => value || 'N/A',
    },
    /*{
      field: 'createdBy', // Adjusted field name
      label: 'Created By',
      valueGetter: (value) => value.email || 'N/A'
    },*/
    {
      field: 'createdAt',
      label: 'Created At',
      valueGetter: (value) =>
        value ? new Date(value.toDate()).toLocaleString() : 'N/A',
    },
    {
      field: 'lastUpdated',
      label: 'Last Updated',
      valueGetter: (value) =>
        value ? new Date(value.toDate()).toLocaleString() : 'N/A',
    },
  ];

  const tableColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <Link
          to={`/accounts/${accountId}/contexts/${contextId}/tags/${params.row.id}`}
          style={{ textDecoration: 'none', color: '#1976d2' }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
    },
    // Corrected Created By Column
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 0.25,
      minWidth: 200,
      sortable: true,
      valueGetter: (value) => value.email || 'N/A',
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 0.25,
      minWidth: 150,
      sortable: true,
      valueGetter: (value) =>
        value ? new Date(value.toDate()).toLocaleString() : 'N/A',
    },
    // Add more columns as needed
  ];

  return (
    <EntityPage
      entityName="Context"
      childEntityName="Tag"
      displayFields={displayFields}
      tableColumns={tableColumns}
      CreateModalComponent={CreateTagModal}
      EditModalComponent={EditTagModal}
      EditMainModalComponent={EditContextModal}
      accountId={accountId} // Pass accountId to EntityPage
      contextId={contextId}
      user={user} // Pass the user prop here
    />
  );
};

ContextPage.propTypes = {
  // No props expected since using context
};

export default ContextPage;
