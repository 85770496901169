// src/theme.js

import { createTheme } from '@mui/material/styles';

// Create the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Primary color
    },
    secondary: {
      main: '#dc004e', // Secondary color
    },
    background: {
      default: '#f0f2f5', // Light background for the app
      paper: '#ffffff', // White background for Paper components
    },
  },
  typography: {
    fontFamily: "'lora', sans-serif",
    h4: {
      fontWeight: 700,
      color: '#333333',
    },
    subtitle2: {
      fontWeight: 1000,
      fontSize: 16,
      color: '#555555',
    },
    body1: {
      fontSize: 16,
      color: '#666666',
    },
    // Define custom typography variants
    tag: {
      fontSize: 36,
      color: '#ffffff',
      fontFamily: "'Orbitron', sans-serif",
      fontWeight: "bold"
      // Inherits fontFamily from default
    },
    it: {
      color: 'transparent',
      WebkitTextStroke: '1px #fff',
      textStroke: '1px #fff', // Note: 'textStroke' is non-standard
      // Inherits fontFamily from default
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10, // Makes Paper components more rounded
          padding: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10, // Makes buttons more rounded
          textTransform: 'none', // Keeps button text as is
          padding: '8px 16px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: '#ffffff',
        },
        columnHeaders: {
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #e0e0e0',
        },
        cell: {
          padding: '12px',
        },
        footerContainer: {
          borderTop: '1px solid #e0e0e0',
        },
      },
    },
    // **Added MuiContainer Overrides**
    MuiContainer: {
      defaultProps: {
        maxWidth: false,       // Removes the maximum width constraint
        disableGutters: true,  // Removes default padding
      },
      styleOverrides: {
        root: {
          width: '90%',        // Ensures the container spans the full width
          padding: 0,          // Optional: Adjust padding if needed
          //margin: 0,           // Optional: Remove default margins
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        maxWidth: false,       // Removes the maximum width constraint
        disableGutters: true,  // Removes default padding
      },
      styleOverrides: {
        root: {
          width: '100%',        // Ensures the container spans the full width
          padding: 0,          // Optional: Adjust padding if needed
          //margin: 0,
          borderRadius: 0,           // Optional: Remove default margins
        },
      },
    },
  },
});

export default theme;
