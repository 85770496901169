// src/components/tags/TagPage.js

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import EntityPage from '../EntityPage';
import EditTagModal from './EditTagModal';
import CreateTagModal from './CreateTagModal';
import DisplayFields from '../common/DisplayFields';
import { UserContext } from '../../contexts/UserContext';
import PropTypes from 'prop-types';

const TagPage = () => {
  const { accountId, contextId, tagId } = useParams();
  const user = useContext(UserContext); // Consume user from context

  console.log('TagPage received user from context:', user);
  console.log('TagPage received accountId:', accountId, 'contextId:', contextId, 'tagId:', tagId);

  // Define the display fields for the Tag
  const displayFields = [
    {
      field: 'name',
      label: 'Tag Name',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'type',
      label: 'Tag Type',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'description',
      label: 'Description',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'status',
      label: 'Status',
      valueGetter: (value) => value || 'N/A',
    },
    {
      field: 'setCookie',
      label: 'Set Cookie',
      valueGetter: (value) => (value ? 'Yes' : 'No'),
    },
    {
      field: 'cookieDomain',
      label: 'Cookie Domain',
      valueGetter: (value) => value || 'N/A',
      condition: (tag) => tag.setCookie,
    },
    {
      field: 'cookieName',
      label: 'Cookie Name',
      valueGetter: (value) => value || 'N/A',
      condition: (tag) => tag.setCookie,
    },
    {
      field: 'sameSiteMode',
      label: 'Same Site Mode',
      valueGetter: (value) => value || 'N/A',
      condition: (tag) => tag.setCookie,
    },
    {
      field: 'collectDeviceData',
      label: 'Collect Device Data',
      valueGetter: (value) => (value ? 'Yes' : 'No'),
    },
    {
      field: 'piiHashFlags',
      label: 'PII Privacy Flags',
      valueGetter: (flags) => {
        if (!flags) return 'N/A';
        const activeFlags = Object.entries(flags)
          .filter(([_, isActive]) => isActive)
          .map(([key]) => key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()));
        return activeFlags.length > 0 ? activeFlags.join(', ') : 'None';
      },
    },
    {
      field: 'allowedRedirectDomains',
      label: 'Allowed Redirect Domains',
      valueGetter: (domains) => {
        if (!domains || domains.length === 0) return 'N/A';
        return domains.join(', ');
      },
      condition: (tag) => tag.type === 'Click Tracking',
    },
    {
      field: 'createdBy',
      label: 'Created By',
      valueGetter: (createdBy) => createdBy?.displayName || createdBy?.email || 'N/A',
    },
    {
      field: 'createdAt',
      label: 'Created At',
      valueGetter: (timestamp) => timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A',
    },
    {
      field: 'lastUpdated',
      label: 'Last Updated',
      valueGetter: (timestamp) => timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A',
    },
    // Add more fields as necessary
  ];

  // Define tableColumns as an empty array since Tag has no children
  const tableColumns = []; // Or omit entirely

  return (
    <EntityPage
      entityName="Tag"
      // No childEntityName since Tag has no children
      childEntityName={null}
      displayFields={displayFields}
      tableColumns={tableColumns}
      // No CreateModalComponent since Tag has no children
      CreateModalComponent={null}
      EditModalComponent={null} 
      EditMainModalComponent={EditTagModal} 
      accountId={accountId}
      contextId={contextId}
      tagId={tagId} // Pass tagId to EntityPage
      user={user}
    />
  );
};

// Define PropTypes (optional if not receiving props)
TagPage.propTypes = {
  // No props expected since using context and useParams
};

export default TagPage;
