// src/components/contexts/EditContextModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Alert,
  Grid,
  CircularProgress,
} from '@mui/material';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import PropTypes from 'prop-types'; // Import PropTypes

// Define Type options
const TYPE_OPTIONS = [
  { value: 'website', label: 'Website' },
  { value: 'app', label: 'App' },
  { value: 'campaign', label: 'Campaign' },
  { value: 'server', label: 'Server' },
  { value: 'other', label: 'Other' },
];

// Define Platform options
const PLATFORM_OPTIONS = [
  { value: 'browser', label: 'Browser' },
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' },
  { value: 'roku', label: 'Roku' },
  { value: 'other', label: 'Other' },
];

const EditContextModal = ({ open, onClose, context, accountId }) => {
  const [name, setName] = useState(context.name || '');
  const [type, setType] = useState(context.type || '');
  const [description, setDescription] = useState(context.description || '');
  const [status, setStatus] = useState(context.status || 'active');
  const [platform, setPlatform] = useState(context.platform || '');
  const [url, setUrl] = useState(context.url || '');
  const [app, setApp] = useState(context.app || '');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // For loading state

  // Clear conditional fields when 'type' changes (if needed)
  useEffect(() => {
    // Since Type is read-only, this useEffect may not be necessary
    // However, keeping it in case Type might change due to future requirements
    if (type !== 'app') {
      setPlatform('');
      setApp('');
    }
    if (type !== 'website') {
      setUrl('');
    }
  }, [type]);

  const handleUpdate = async () => {
    // Basic validations
    if (!description.trim()) {
      setError('Description is required.');
      return;
    }

    // Additional validations based on 'type'
    if (type === 'app') {
      if (!platform) {
        setError('Platform is required for App type.');
        return;
      }
      if (!app.trim()) {
        setError('App ID is required for App type.');
        return;
      }
    }
    if (type === 'website') {
      if (!url.trim()) {
        setError('URL is required for Website type.');
        return;
      }
      // Optional: Validate URL format
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + // domain name
          'localhost|' + // localhost
          '\\d{1,3}(\\.\\d{1,3}){3})' + // OR IP (v4) address
          '(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*' + // port and path
          '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
          '(\\#[-a-zA-Z0-9_]*)?$',
        'i'
      ); // fragment locator
      if (!urlPattern.test(url)) {
        setError('Please enter a valid URL.');
        return;
      }
    }

    try {
      setLoading(true); // Start loading
      setError(null); // Reset any existing errors

      const contextDocRef = doc(db, 'accounts', accountId, 'contexts', context.id);
      const updatedData = {
        // Name and Type are read-only; no need to update them
        description: description.trim() || null,
        status,
        platform: type === 'app' ? platform : null,
        url: type === 'website' ? url.trim() : null,
        app: type === 'app' ? app.trim() : null,
        lastUpdated: serverTimestamp(),
      };

      await updateDoc(contextDocRef, updatedData);

      // Optionally, display a success message or close the modal
      onClose();
    } catch (error) {
      console.error('Error updating context:', error);
      setError('Failed to update context. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleClose = () => {
    setError(null);
    setDescription(context.description || '');
    setStatus(context.status || 'active');
    setPlatform(context.platform || '');
    setUrl(context.url || '');
    setApp(context.app || '');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Context</DialogTitle>
      <DialogContent>
        {/* Display Error Message */}
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Name Field - Read-Only */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={name}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              helperText="Name of the context (read-only)"
            />
          </Grid>

          {/* Type Field - Read-Only */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Type"
              value={type}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              helperText="Type of the context (read-only)"
            />
          </Grid>

          {/* Platform Field - Only appears if Type = App */}
          {type === 'app' && (
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Platform"
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
                required={type === 'app'}
                fullWidth
                helperText="Select the platform"
              >
                {PLATFORM_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {/* App ID Field - Only appears if Type = App */}
          {type === 'app' && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="App ID"
                value={app}
                onChange={(e) => setApp(e.target.value)}
                required={type === 'app'}
                fullWidth
                helperText="Enter the unique App ID"
              />
            </Grid>
          )}

          {/* URL Field - Only appears if Type = Website */}
          {type === 'website' && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required={type === 'website'}
                fullWidth
                helperText="Enter the URL for your website"
              />
            </Grid>
          )}

          {/* Description Field */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              helperText="Enter a description for the context (optional)"
            />
          </Grid>

          {/* Status Field */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
              fullWidth
              helperText="Set the status of the context"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for type checking and better maintainability
EditContextModal.propTypes = {
  open: PropTypes.bool.isRequired, // Controls the visibility of the modal
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  context: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    platform: PropTypes.string,
    url: PropTypes.string,
    app: PropTypes.string,
  }).isRequired,
  accountId: PropTypes.string.isRequired, // ID of the parent account
};

export default EditContextModal;
