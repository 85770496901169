// src/components/DataTable.js

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const DataTable = ({ rows, columns }) => {
  return (
    <Box sx={{ height: '100%', width: '100%', '& .MuiDataGrid-root': { border: 'none' } }}>
      <DataGrid
        rows={rows}
        //rowHeight={60}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection={false}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontSize: 16,
            fontWeight: 1000,
          },
          '& .MuiDataGrid-row': {
            fontSize: 16,
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#fafafa',
            fontSize: 16.5,
          },
          '& .MuiDataGrid-cell': {
            padding: '0px 12px',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: '1px solid #e0e0e0',
          },
        }}
      />
    </Box>
  );
};

export default DataTable;
