// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyA0d9aQ0s2oLfzpGhLqwmB8MK2GRZ8axf8",
    authDomain: "tagit-live.firebaseapp.com",
    projectId: "tagit-live",
    storageBucket: "tagit-live.firebasestorage.app",
    messagingSenderId: "1041179548097",
    appId: "1:1041179548097:web:edef5bfa80f177c17ff740"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
