// src/components/EntityPage.js

import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  collection,
  onSnapshot,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  Button,
  Container,
  Typography,
  Breadcrumbs,
  Alert,
  Grid,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DataTable from './common/DataTable';
import DisplayFields from './common/DisplayFields'; 
import PropTypes from 'prop-types';
import { AlignVerticalCenter } from '@mui/icons-material';

/**
 * EntityPage Component
 *
 * A generic component to display and manage entities like Account, Context, or Tag.
 *
 * Props:
 * - entityName: string (e.g., 'Account', 'Context', 'Tag')
 * - childEntityName: string (e.g., 'Context', 'Tag') or null
 * - displayFields: array of objects defining fields to display
 * - tableColumns: array of objects defining columns for DataTable
 * - CreateModalComponent: React component for creating child entities
 * - EditModalComponent: React component for editing child entities
 * - EditMainModalComponent: React component for editing the main entity
 * - accountId: string (ID of the parent account)
 * - contextId: string (ID of the parent context, if applicable)
 * - tagId: string (ID of the parent tag, if applicable)
 * - user: object (user information from context)
 */


const EntityPage = ({
  entityName,
  childEntityName,
  displayFields,
  tableColumns,
  CreateModalComponent,
  EditModalComponent,
  EditMainModalComponent,
  accountId,
  contextId,
  tagId,
  user,
}) => {
  /**
   * Memoize the Firestore document reference to prevent unnecessary re-renders
   */
  const entityDocRef = useMemo(() => {
    if (entityName === 'Account') {
      return doc(db, 'accounts', accountId);
    } else if (entityName === 'Context') {
      return doc(db, 'accounts', accountId, 'contexts', contextId);
    } else if (entityName === 'Tag') {
      if (!tagId) {
        console.error('Tag ID is required for Tag entity.');
        return null;
      }
      return doc(
        db,
        'accounts',
        accountId,
        'contexts',
        contextId,
        'tags',
        tagId
      );
    } else {
      console.error(`Unsupported entityName: ${entityName}`);
      return null;
    }
  }, [entityName, accountId, contextId, tagId]);

  /**
   * Define the child collection path based on childEntityName
   */
  const childrenCollection = childEntityName
    ? `${childEntityName.toLowerCase()}s`
    : null; // e.g., 'contexts', 'tags'

  // State variables
  const [entity, setEntity] = useState(null); // Main entity data
  const [parentAccount, setParentAccount] = useState(null); // For Context and Tag
  const [parentContext, setParentContext] = useState(null); // For Tag
  const [childEntities, setChildEntities] = useState([]); // Child entities data
  const [loadingEntity, setLoadingEntity] = useState(true); // Loading state for main entity
  const [loadingChildren, setLoadingChildren] = useState(true); // Loading state for child entities
  const [openCreateModal, setOpenCreateModal] = useState(false); // State to control Create Modal
  const [openEditModal, setOpenEditModal] = useState(false); // State to control Edit Child Modal
  const [openMainEditModal, setOpenMainEditModal] = useState(false); // State to control Edit Main Entity Modal
  const [selectedChild, setSelectedChild] = useState(null); // Currently selected child for editing
  const [errorEntity, setErrorEntity] = useState(null); // Error state for main entity
  const [errorChildren, setErrorChildren] = useState(null); // Error state for child entities


  /**
   * Fetch main entity data in real-time using onSnapshot
   */
  useEffect(() => {
    if (!entityDocRef) {
      setLoadingEntity(false);
      return;
    }

    console.log(`Fetching ${entityName} from path: ${entityDocRef.path}`);

    const unsubscribe = onSnapshot(
      entityDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setEntity({ id: docSnap.id, ...data });
          setErrorEntity(null);
        } else {
          console.error(
            `No ${entityName.toLowerCase()} found at path: ${entityDocRef.path}`
          );
          setErrorEntity(`No such ${entityName.toLowerCase()} found.`);
        }
        setLoadingEntity(false);
      },
      (error) => {
        console.error(`Error fetching ${entityName.toLowerCase()}:`, error);
        setErrorEntity(`Failed to fetch ${entityName.toLowerCase()}. Please try again.`);
        setLoadingEntity(false);
      }
    );

    // Cleanup subscription on unmount or when entityDocRef changes
    return () => unsubscribe();
  }, [entityDocRef, entityName]);

  /**
   * Fetch parent entities if necessary (e.g., Account for Context, Context for Tag)
   */
  useEffect(() => {
    const fetchParents = async () => {
      if (entityName === 'Context') {
        // Fetch parent Account
        try {
          const accountDocRef = doc(db, 'accounts', accountId);
          const accountSnap = await getDoc(accountDocRef);
          if (accountSnap.exists()) {
            const accountData = accountSnap.data();
            setParentAccount({ id: accountSnap.id, ...accountData });
          } else {
            console.error(`No Account found with ID: ${accountId}`);
            setErrorEntity(`Parent Account not found.`);
          }
        } catch (error) {
          console.error('Error fetching parent Account:', error);
          setErrorEntity(`Failed to fetch parent Account.`);
        }
      } else if (entityName === 'Tag') {
        // Fetch parent Context
        try {
          const contextDocRef = doc(db, 'accounts', accountId, 'contexts', contextId);
          const contextSnap = await getDoc(contextDocRef);
          if (contextSnap.exists()) {
            const contextData = contextSnap.data();
            setParentContext({ id: contextSnap.id, ...contextData });
          } else {
            console.error(`No Context found with ID: ${contextId}`);
            setErrorEntity(`Parent Context not found.`);
          }
        } catch (error) {
          console.error('Error fetching parent Context:', error);
          setErrorEntity(`Failed to fetch parent Context.`);
        }

        // Fetch parent Account for Tag
        try {
          const accountDocRef = doc(db, 'accounts', accountId);
          const accountSnap = await getDoc(accountDocRef);
          if (accountSnap.exists()) {
            const accountData = accountSnap.data();
            setParentAccount({ id: accountSnap.id, ...accountData });
          } else {
            console.error(`No Account found with ID: ${accountId}`);
            setErrorEntity(`Parent Account not found.`);
          }
        } catch (error) {
          console.error('Error fetching parent Account:', error);
          setErrorEntity(`Failed to fetch parent Account.`);
        }
      }
    };

    if (entity) {
      fetchParents();
    }
  }, [entity, entityName, accountId, contextId]);

  /**
   * Fetch child entities in real-time using onSnapshot
   */
  useEffect(() => {
    if (!entity || !childEntityName) {
      setLoadingChildren(false);
      return;
    }

    const childrenRef = collection(db, entityDocRef.path, childrenCollection);

    const unsubscribe = onSnapshot(
      childrenRef,
      (snapshot) => {
        const childrenData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChildEntities(childrenData);
        setErrorChildren(null);
        setLoadingChildren(false);
      },
      (error) => {
        console.error(`Error fetching ${childEntityName.toLowerCase()}s:`, error);
        setErrorChildren(`Failed to fetch ${childEntityName.toLowerCase()}s. Please try again.`);
        setLoadingChildren(false);
      }
    );

    // Cleanup subscription on unmount or when dependencies change
    return () => unsubscribe();
  }, [childEntityName, entity, entityDocRef?.path, childrenCollection]);

  /**
   * Handlers to open and close modals
   */
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleEditChild = (child) => {
    setSelectedChild(child);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedChild(null);
  };

  const handleOpenMainEditModal = () => {
    setOpenMainEditModal(true);
  };

  const handleCloseMainEditModal = () => {
    setOpenMainEditModal(false);
  };

  const handleDeleteChild = async (childId) => {
    if (window.confirm(`Are you sure you want to delete this ${childEntityName.toLowerCase()}?`)) {
      try {
        const childDocRef = doc(
          db,
          entityDocRef.path,
          childrenCollection,
          childId
        );
        await deleteDoc(childDocRef);
        console.log(`${childEntityName} deleted successfully.`);
      } catch (error) {
        console.error(`Error deleting ${childEntityName.toLowerCase()}:`, error);
        setErrorChildren(`Failed to delete ${childEntityName.toLowerCase()}. Please try again.`);
      }
    }
  };

  /**
   * Transform displayFields to include values from the main entity
   */
  const transformedDisplayFields = displayFields.map((field) => ({
    ...field,
    value: field.valueGetter(entity ? entity[field.field] : null),
  }));

  /**
   * Append Actions column to tableColumns if childEntityName is provided
   */
  const extendedTableColumns = childEntityName
    ? [
        ...tableColumns,
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 0.25,
          minWidth: 200,
          sortable: false,
          renderCell: (params) => (
            <Box display="inline-flex" gap={1}>
              <Button
                variant="contained"
                //color="primary"
                size="small"
                onClick={() => handleEditChild(params.row)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => handleDeleteChild(params.row.id)}
              >
                Delete
              </Button>
            </Box>
          ),
        },
      ]
    : [];

  /**
   * Build breadcrumbs array based on entity hierarchy
   */
  const buildBreadcrumbs = () => {
    const breadcrumbs = [
      { label: 'Accounts', path: '/' },
    ];
  
    if (entityName === 'Account') {
      // Push the account name without a path to make it non-clickable
      breadcrumbs.push({ label: entity ? entity.name : entityName });
    } else if (entityName === 'Context') {
      if (parentAccount) {
        breadcrumbs.push({ label: parentAccount.name, path: `/accounts/${accountId}` });
      }
      // Push the context name without a path
      breadcrumbs.push({ label: entity ? entity.name : entityName });
    } else if (entityName === 'Tag') {
      if (parentAccount) {
        breadcrumbs.push({ label: parentAccount.name, path: `/accounts/${accountId}` });
      }
      if (parentContext) {
        breadcrumbs.push({ label: parentContext.name, path: `/accounts/${accountId}/contexts/${contextId}` });
      }
      // Push the tag name without a path
      breadcrumbs.push({ label: entity ? entity.name : entityName });
    }
  
    console.log('Generated Breadcrumbs:', breadcrumbs); // Debugging Line
    return breadcrumbs;
  };  

  /**
   * Determine how to pass the selectedChild to the EditModalComponent
   */
  let modalEntityProp = {};
  if (childEntityName === 'Context') {
    modalEntityProp = { context: selectedChild };
  } else if (childEntityName === 'Tag') {
    modalEntityProp = { tag: selectedChild };
  }

  /**
   * Determine how to pass the main entity to the EditMainModalComponent
   */
  let mainEntityProp = {};
  switch (entityName) {
    case 'Account':
      mainEntityProp = { account: entity };
      break;
    case 'Context':
      mainEntityProp = { context: entity };
      break;
    case 'Tag':
      mainEntityProp = { tag: entity };
      break;
    default:
      mainEntityProp = { entity };
  }

  /**
   * Debugging Logs (Optional)
   */
  //console.log('Selected Child:', selectedChild);
  //console.log('Main Entity Prop:', mainEntityProp);

  /**
   * Render loading and error states for main entity
   */
  if (loadingEntity) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6">Loading {entityName.toLowerCase()}...</Typography>
        </Box>
      </Container>
    );
  }

  if (errorEntity) {
    return (
      <Container>
        <Alert severity="error">{errorEntity}</Alert>
      </Container>
    );
  }

  if (!entity) {
    return (
      <Container>
        <Alert severity="warning">
          {entityName} data is unavailable.
        </Alert>
      </Container>
    );
  }

  /**
   * Main Render
   */
  return (
    <Container>
      {/* Row 1: Page Title (left aligned) */}
      <Grid container spacing={2} alignItems="center" sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4">
            {entityName}
          </Typography>
        </Grid>
        <Grid item sm={8}>
          {/* Empty or additional content can go here */}
        </Grid>
      </Grid>

      {/* Row 2: Breadcrumbs (left aligned) | Edit Button (right aligned) */}
      <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={8}>
        <Breadcrumbs aria-label="breadcrumb">
  {buildBreadcrumbs().map((crumb, index) => {
    const isLast = index === buildBreadcrumbs().length - 1;
    return crumb.path ? (
      <Link
        key={index}
        to={crumb.path}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <Typography variant="body1" color="inherit">
          {crumb.label}
        </Typography>
      </Link>
    ) : (
      <Typography
        key={index}
        color="primary" // Use a distinct color from the theme
        variant={isLast ? 'h6' : 'body1'} // 'h6' variant for the last breadcrumb
        sx={{
          fontWeight: isLast ? 'bold' : 'normal',
          // Optionally, adjust margin or padding
        }}
      >
        {crumb.label}
      </Typography>
    );
  })}
</Breadcrumbs>

        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
          {EditMainModalComponent && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleOpenMainEditModal}
            >
              Edit {entityName}
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Row 3: DisplayFields Component */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <DisplayFields fields={transformedDisplayFields} />
        </Grid>
      </Grid>

      {/* Row 4: Subtitle (left aligned) */}
      {childEntityName && (
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5">
              {childEntityName}s
            </Typography>
          </Grid>
          <Grid item sm={8}>
            {/* Empty or additional content can go here */}
          </Grid>
        </Grid>
      )}

      {/* Row 5: Create Button (right aligned) */}
      {childEntityName && (
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item sm={8}>
            {/* Empty */}
          </Grid>
          <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenCreateModal}
            >
              Create {childEntityName}
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Row 6: DataTable Component */}
      {childEntityName && (
        <>
          {/* Displaying Errors Related to Child Entities */}
          {errorChildren && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {errorChildren}
            </Alert>
          )}

          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              {loadingChildren ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                  <Typography variant="h6">Loading {childEntityName.toLowerCase()}s...</Typography>
                </Box>
              ) : (
                <DataTable
                  rows={childEntities}
                  columns={extendedTableColumns}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}

      {/* Edit Child Entity Modal */}
      {selectedChild && EditModalComponent && (
        <EditModalComponent
          open={openEditModal}
          onClose={handleCloseEditModal}
          {...modalEntityProp} // Pass 'context' or 'tag' based on childEntityName
          accountId={accountId}
          contextId={contextId} // Pass contextId if applicable
        />
      )}

      {/* Edit Main Entity Modal */}
      {EditMainModalComponent && entity && (
        <EditMainModalComponent
          open={openMainEditModal}
          onClose={handleCloseMainEditModal}
          {...mainEntityProp} // Pass 'account', 'context', or 'tag' based on entityName
          accountId={accountId}
          contextId={contextId} // Pass contextId if applicable
          tagId={tagId} // Pass tagId if applicable
        />
      )}

      {/* Create Child Entity Modal */}
      {CreateModalComponent && (
        <CreateModalComponent
          open={openCreateModal}
          onClose={handleCloseCreateModal}
          user={user}
          accountId={accountId}
          contextId={contextId} // Pass contextId if applicable
        />
      )}
    </Container>
  );
};

/**
 * Define PropTypes for type checking and documentation
 */
EntityPage.propTypes = {
  entityName: PropTypes.string.isRequired,
  childEntityName: PropTypes.string, // Make it optional
  displayFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  CreateModalComponent: PropTypes.elementType,
  EditModalComponent: PropTypes.elementType,
  EditMainModalComponent: PropTypes.elementType, // Specific component for main entity edit modal
  accountId: PropTypes.string.isRequired,
  contextId: PropTypes.string,
  tagId: PropTypes.string, // Optional, only for Tag entity
  user: PropTypes.object,
};

/**
 * Define default props in case some are not provided
 */
EntityPage.defaultProps = {
  childEntityName: null,
  CreateModalComponent: null,
  EditModalComponent: null,
  EditMainModalComponent: null,
  contextId: null,
  tagId: null,
};

export default EntityPage;
