// src/components/Login.js

import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase'; // Ensure only auth is imported
import { useNavigate } from 'react-router-dom';
import GoogleButton from 'react-google-button'; // Import GoogleButton

const Login = () => {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate(); // Hook for navigation

  const handleLogin = async () => {
    console.log('Google sign-in initiated');
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Authentication popup closed successfully');
      console.log('User information:', result.user);

      // Optionally, retrieve additional user info
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log('Access Token:', token);

      // Redirect to dashboard after successful login
      navigate('/'); // Update this path as needed
    } catch (error) {
      console.error('Login error code:', error.code);
      console.error('Login error message:', error.message);
      console.error('Login error details:', error);

      if (error.code === 'auth/popup-closed-by-user') {
        alert('Authentication popup was closed before completion. Please try again.');
      } else if (error.code === 'auth/cancelled-popup-request') {
        alert('Authentication popup request was cancelled. Please try again.');
      } else {
        alert('An unexpected error occurred during login. Please try again.');
      }
    }
  };

  return (
    <div style={styles.container}>
      <h1>Login</h1>
      <GoogleButton
        onClick={handleLogin}
        style={{ width: '100%', maxWidth: '300px' }} // Optional: Adjust width as needed
      />
    </div>
  );
};

// Optional: Add some basic styling
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
};

export default Login;
